import React, { useEffect, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import Footer from "../../Shared/Footer/Footer";
import Navigation from "../../Shared/Navigation/Navigation";
import "./Career.css";
import JobCirculer from "./JobCirculer/JobCirculer";

const Career = () => {
  const [career, setCareer] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // console.log(career);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://backend.frameltd.biz/job_circular_list/")
      .then((res) => res.json())
      .then((data) => setCareer(data?.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="text-center loading-spinner">
        <Spinner animation="grow" />
      </div>
    );
  }
  return (
    <>
      <Navigation></Navigation>
      <div className="container-fluid py-5 career-container">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3">
              <h1 className="join-us-title">{career?.title}</h1>
              <p className="join-us-sub-title">{career?.description}</p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
              <img
                src={career?.image_url}
                alt=""
                className="img-fluid d-block ms-auto"
              />
            </div>
          </div>
        </div>

        <h1 className="career-title py-5">Open Positions </h1>
        <div className="container pt-3">
          <Accordion>
            {career?.career?.map((circular, index) => (
              <JobCirculer key={circular?.id} circular={circular}></JobCirculer>
            ))}
            {/* <JobCirculer></JobCirculer>
                        <JobCirculer></JobCirculer> */}
          </Accordion>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Career;
