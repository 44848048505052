import React from "react";
import "./OutClient.css";

const OurClient = ({ home }) => {
  return (
    <div className="container-fluid home-client-container py-5" id="client">
      <div className="container">
        <div className="team-top-content-container">
          <button className="client-btn">{home?.client?.sub_title}</button>
          <h1 className="home-client-title">{home?.client?.title}</h1>
          <img
            src={home?.client?.image_url}
            alt=""
            className="img-fluid pt-5"
          />
        </div>
      </div>
    </div>
  );
};

export default OurClient;
