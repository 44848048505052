import React from 'react';
import './HomeDepartmentsCard.css';

const HomeDepartmentsCard = ({ department }) => {

    return (
        <div className='col'>

            <div className="departments-item-card h-100 p-3">
                {/* <button className='deparments-items-btn'>
                    {department?.serial_no}
                </button> */}
                <img src={department?.icon_url} alt="" width={"40"} className="img-fluid deparments-items-btn" />
                <div className="departments-card-body">
                    <h5 className="departments-card-title">{department?.title}</h5>
                    <p className="departments-card-detaisl-text">{department?.description}</p>
                </div>
            </div>
        </div>
    );
};

export default HomeDepartmentsCard;