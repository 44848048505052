import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Footer from "../Shared/Footer/Footer";
import Navigation from "../Shared/Navigation/Navigation";
import "./Home.css";
import HomeBanner from "./HomeBanner/HomeBanner";
import HomeContact from "./HomeContact/HomeContact";
import HomeDepartments from "./HomeDepartments/HomeDepartments";
import HomeMission from "./HomeMission/HomeMission";
import HomeTeam from "./HomeTeam/HomeTeam";
import HomeVision from "./HomeVision/HomeVision";
import OurClient from "./OurClient/OurClient";

const Home = () => {
  const [home, setHome] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://backend.frameltd.biz/home_details/")
      .then((res) => res.json())
      .then((data) => setHome(data?.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="text-center loading-spinner">
        <Spinner animation="grow" />
      </div>
    );
  }
  return (
    <div>
      <Navigation home={home}></Navigation>
      <HomeBanner home={home}></HomeBanner>
      <HomeMission home={home}></HomeMission>
      <HomeVision home={home}></HomeVision>
      <HomeDepartments home={home}></HomeDepartments>
      <HomeTeam home={home}></HomeTeam>
      <OurClient home={home}></OurClient>
      <HomeContact home={home}></HomeContact>
      <Footer home={home}></Footer>
    </div>
  );
};

export default Home;
