import React from "react";
import "./HomeMission.css";

const HomeMission = ({ home }) => {
  return (
    <div className="container-fluid home-mission-container py-5">
      <div className="container ">
        <div className="row align-items-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3">
            <img
              src={home?.mission?.image_url}
              alt=""
              className="img-fluid "
              width={"554"}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6  p-lg-5">
            <button className="mission-btn">{home?.mission?.sub_title}</button>
            <h1 className="home-mission-title">{home?.mission?.title} </h1>
            <p className="home-mission-sub-text">
              {home?.mission?.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMission;
