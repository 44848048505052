import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Navigation.css";

const Navigation = () => {
  const [home, setHome] = useState({});

  useEffect(() => {
    fetch("https://backend.frameltd.biz/home_details/")
      .then((res) => res.json())
      .then((data) => setHome(data?.data));
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg=""
      variant="dark"
      className={"header-container"}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="header-brand-title">
          <img src={home?.logo_url} alt="" className="img-fluid" width={"80"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto text-center">
            <Nav.Link
              as={HashLink}
              to="#about"
              className="header-menu-title me-lg-5"
            >
              About
            </Nav.Link>
            <Nav.Link
              as={HashLink}
              to="/home#services"
              className="header-menu-title me-lg-5"
            >
              Services
            </Nav.Link>
            <Nav.Link
              as={HashLink}
              to="/home#team"
              className="header-menu-title me-lg-5"
            >
              Team
            </Nav.Link>
            <Nav.Link
              as={HashLink}
              to="/home#client"
              className="header-menu-title me-lg-5"
            >
              Clients
            </Nav.Link>
            <Nav.Link
              as={HashLink}
              to="/career"
              className="header-menu-title me-lg-5"
            >
              Career
            </Nav.Link>
          </Nav>
          <Nav className="text-center">
            <Nav.Link href="#deets">
              <button className="header-contact-us-btn">Contact US</button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
