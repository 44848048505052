import React from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import "./HomeTeam.css";
import TeamCard from "./TeamCard/TeamCard";

const HomeTeam = ({ home }) => {
  console.log("Home team",home?.team?.team_description)
  return (
    <div className="container-fluid home-team-container py-5" id="team">
      <div className="container ">
        <div className="team-top-content-container">
          <button className="team-btn">{home?.team?.sub_title}</button>
          <h1 className="home-team-title">{home?.team?.title}</h1>
          <p className="home-team-sub-text">{home?.team?.description}</p>
          {/* <button className='about-us-btn'>{home?.team?.button_text}</button> */}
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center">
          <div className="single-team-item-card h-100 py-5">
            <img
              src={home?.team?.team_description?.team_list[0]?.image_url}
              alt=""
              className="img-fluid mx-auto py-4 team-img rounded-circle"
              width={"100"}
            />
            <div className="team-card-body">
              <div className="h-50">
                <h5 className="team-card-title m-0">
                  {home?.team?.team_description?.team_list[0]?.title}
                </h5>
              </div>
              <p className="team-card-designation-text m-0">
                {home?.team?.team_description?.team_list[0]?.designation}
              </p>
            </div>
            <div className="team-card-footer social-media-holder">
              <a
                href={home?.team?.team_description?.team_list[0]?.facebook_url}
                target={"_blank"}
                rel="noreferrer"
              >
                <FaFacebookF className="social-icon me-4"></FaFacebookF>
              </a>
              <a
                href={home?.team?.team_description?.team_list[0]?.twitter_url}
                target={"_blank"}
                rel="noreferrer"
              >
                <FaLinkedinIn className="social-icon"></FaLinkedinIn>
              </a>
            </div>
          </div>
          <div className="single-team-item-card py-5 h-100">
            <img
              src={home?.team?.team_description?.team_list[1]?.image_url}
              alt=""
              className="img-fluid mx-auto py-4 team-img rounded-circle"
              width={"100"}
            />
            <div className="team-card-body">
              <div className="h-50">
                <h5 className="team-card-title m-0">
                  {home?.team?.team_description?.team_list[1]?.title}
                </h5>
              </div>
              <p className="team-card-designation-text m-0">
                {home?.team?.team_description?.team_list[1]?.designation}
              </p>
            </div>
            <div className="team-card-footer social-media-holder">
              <a
                href={home?.team?.team_description?.team_list[1]?.facebook_url}
                target={"_blank"}
                rel="noreferrer"
              >
                <FaFacebookF className="social-icon me-4"></FaFacebookF>
              </a>
              <a
                href={home?.team?.team_description?.team_list[1]?.twitter_url}
                target={"_blank"}
                rel="noreferrer"
              >
                <FaLinkedinIn className="social-icon"></FaLinkedinIn>
              </a>
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 align-items-center py-5">
          {home?.team?.team_description?.team_list
            ?.slice(2)
            ?.map((team, index) => (
              <TeamCard key={team.id} team={team}></TeamCard>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeTeam;
